import { Outlet, json, redirect } from 'remix';
import type { LoaderFunction } from 'remix';
import { SDK } from '~/utils/sdk.server';
import { Layout } from 'wally-design-system'

export const loader: LoaderFunction = async ({ context, request }) => {
  // const { env, sessionStorage } = context
  // const { API_HOST, JWT_SECRET_KEY: KEY } = env;
  return null;
};

const Login = () => <Layout>
  <div className="text-neutral-800 text-subheading-bold">Login</div>
  </Layout>;

export default Login